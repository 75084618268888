import _setPrototypeOf3 from "../core-js/object/set-prototype-of";
import _create3 from "../core-js/object/create";
import _typeof from "../helpers/typeof";
var exports = {};
exports.__esModule = true;
var _setPrototypeOf = _setPrototypeOf3;

var _setPrototypeOf2 = _interopRequireDefault(_setPrototypeOf);

var _create = _create3;

var _create2 = _interopRequireDefault(_create);

var _typeof2 = _typeof;

var _typeof3 = _interopRequireDefault(_typeof2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + (typeof superClass === "undefined" ? "undefined" : (0, _typeof3.default)(superClass)));
  }

  subClass.prototype = (0, _create2.default)(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) _setPrototypeOf2.default ? (0, _setPrototypeOf2.default)(subClass, superClass) : subClass.__proto__ = superClass;
};

export default exports;
export const __esModule = exports.__esModule;